import React from 'react';
import './Navbar.css';
import { BiHome, BiEnvelope, BiFileBlank, BiServer, BiUser } from "react-icons/bi";
import { useTrackVisibility } from 'react-intersection-observer-hook';

const Navbar = (props) => {
  // /**
  //  * Navbar links active state on scroll
  //  */
  // let navbarlinks = props.select('#navbar .scrollto', true)
  // const navbarlinksActive = () => {
  //   let position = window.scrollY + 200
  //   navbarlinks.forEach(navbarlink => {
  //     console.log("navbarlink: " + navbarlink)
  //     if (!navbarlink.hash) {
  //       console.log("!navbarlink.hash")
  //       return
  //     }
  //     let section = props.select(navbarlink.hash)
  //     if (!section) {
  //       console.log("!section" + section)
  //       return
  //     }
  //     if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
  //       navbarlink.classList.add('active')
  //     } else {
  //       navbarlink.classList.remove('active')
  //     }
  //     console.log("navbarlink" + navbarlink)
  //   })
  // }
  // window.addEventListener('load', navbarlinksActive)
  // props.onscroll(document, navbarlinksActive)

  // const ref1 = useRef(null);
  // const isInViewport1 = props.useIsInViewport(ref1);
  // const ref2 = useRef(null);
  // const isInViewport2 = props.useIsInViewport(ref2);
  // const ref3 = useRef(null);
  // const isInViewport3 = props.useIsInViewport(ref3);
  // const ref4 = useRef(null);
  // const isInViewport4 = props.useIsInViewport(ref4);
  // const ref5 = useRef(null);
  // const isInViewport5 = props.useIsInViewport(ref5);
  // const ref6 = useRef(null);
  // const isInViewport6 = props.useIsInViewport(ref6);


  const [
    introRef,
    { isVisible: isIntroVisible, rootRef: introRootRef },
  ] = useTrackVisibility();

  const [
    aboutRef,
    { isVisible: isAboutVisible, rootRef: aboutRootRef },
  ] = useTrackVisibility();

  const [
    resumeRef,
    { isVisible: isResumeVisible, rootRef: resumeRootRef },
  ] = useTrackVisibility();

  const [
    personalProjectsRef,
    { isVisible: isPersonalProjectsVisible, rootRef: personalProjectsRootRef },
  ] = useTrackVisibility();

  const [
    servicesRef,
    { isVisible: isProjectsVisible, rootRef: servicesRootRef },
  ] = useTrackVisibility();

  const [
    contactRef,
    { isVisible: isContactVisible, rootRef: contactRootRef },
  ] = useTrackVisibility();

  /**
   * Mobile nav toggle
   */
  props.on('click', '.mobile-nav-toggle', function(e) {
    props.select('body').classList.toggle('mobile-nav-active')
    this.classList.toggle('bi-list')
    this.classList.toggle('bi-x')
  })

  /**
   * Scrool with ofset on links with a class name .scrollto
   */
  props.on('click', '.scrollto', function(e) {
    if (props.select(this.hash)) {
      e.preventDefault()

      let body = props.select('body')
      if (body.classList.contains('mobile-nav-active')) {
        body.classList.remove('mobile-nav-active')
        let navbarToggle = props.select('.mobile-nav-toggle')
        navbarToggle.classList.toggle('bi-list')
        navbarToggle.classList.toggle('bi-x')
      }
      props.scrollto(this.hash)
    }
  }, true)

  /**
   * Scroll with ofset on page load with hash links in the url
   */
  window.addEventListener('load', () => {
    if (window.location.hash) {
      if (props.select(window.location.hash)) {
        props.scrollto(window.location.hash)
      }
    }
  });

  return (
    <header id="header" className="header">
        <nav id="navbar" className="navbar_personal_website nav-menu">
            <ul>
                <li><a href="#hero" ref={introRef} className={`nav-link scrollto ${isIntroVisible ? "active" : ""}`}> <BiHome size={20}/> <span>Home</span></a></li>
                <li><a href="#about" ref={aboutRef} className={`nav-link scrollto ${isAboutVisible ? "active" : ""}`}> <BiUser size={20}/> <span>About</span></a></li>
                <li><a href="#resume" ref={resumeRef} className={`nav-link scrollto ${isResumeVisible ? "active" : ""}`}> <BiFileBlank size={20}/> <span>Resume</span></a></li>
                <li><a href="#projects" ref={servicesRef} className={`nav-link scrollto ${isProjectsVisible ? "active" : ""}`}> <BiServer size={20}/> <span>Projects</span></a></li>
                <li><a href="#contact" ref={contactRef} className={`nav-link scrollto ${isContactVisible ? "active" : ""}`}> <BiEnvelope size={20}/>  <span>Contact</span></a></li>
            </ul>
        </nav>
    </header>
  );
}

export default Navbar;
