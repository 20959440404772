import React from 'react';
import './Footer.css';
import { BiLogoGithub, BiMailSend, BiLogoLinkedin } from 'react-icons/bi';
import { CgFileDocument } from 'react-icons/cg';

const Footer = (props) => {
  return (
    <footer id="footer">
      <div class="container">
        {/* <h3>Tristan Jin</h3>
        <p>Et aut eum quis fuga eos sunt ipsa nihil. Labore corporis magni eligendi fuga maxime saepe commodi placeat.</p> */}
        <div class="social-links">
        <a href="https://www.linkedin.com/in/tristan-jin/" target="_blank" rel="noreferrer" className="linkedin"> <BiLogoLinkedin size={20}/> </a>
          <a href="https://github.com/tjin88/" target="_blank" rel="noreferrer" className="github"> <BiLogoGithub size={20}/> </a>
          <a href="mailto:tjin368@gmail.com" target="_blank" rel="noreferrer" className="email"> <BiMailSend size={20}/> </a>
          <a href="https://drive.google.com/file/d/1NLYnoAV0RwOIOFeNQgTTszRmC_k6oNei/view?usp=sharing" target="_blank" rel="noreferrer" className="Resume"> <CgFileDocument size={20}/> </a>
        </div>
        <div class="copyright">
          &copy; Developed by <strong><span>Tristan Jin</span></strong>, 2023
        </div>
      </div>
    </footer>
  );
}

export default Footer;
