import React from 'react';
import './Intro.css';
import { TypeAnimation } from 'react-type-animation';
import { BiLogoLinkedin, BiLogoGithub, BiMailSend } from "react-icons/bi";
import { CgFileDocument } from "react-icons/cg";

const Navbar = (props) => {
  return (
    <section id="hero" className="header">
      <div className="container" data-aos="zoom-in" data-aos-delay="100">
        <h1>Tristan Jin</h1>
        {/* <p>I'm <span className="typed" data-typed-items="a Programmer, a Student, a Tennis Coach, a Runner, an Avid Sleeper"></span></p> */}
        <p>I'm 
          <TypeAnimation
            className="typingAnimation"
            sequence={[
              ' a Programmer', 1000,
              ' a Student', 1000,
              ' a Tennis Coach', 1000,
              ' a Runner', 1000,
              ' an Avid Sleeper', 1000
            ]}
            wrapper="span"
            speed={50}
            style={{ fontSize: '26px', display: 'inline-block' }}
            repeat={Infinity}
          />
        </p>
        <div className="social-links">
          <a href="https://www.linkedin.com/in/tristan-jin/" target="_blank" rel="noreferrer" className="linkedin"> <BiLogoLinkedin size={20}/> </a>
          <a href="https://github.com/tjin88/" target="_blank" rel="noreferrer" className="github"> <BiLogoGithub size={20}/> </a>
          <a href="mailto:tjin368@gmail.com" target="_blank" rel="noreferrer" className="email"> <BiMailSend size={20}/> </a>
          <a href="https://drive.google.com/file/d/1NLYnoAV0RwOIOFeNQgTTszRmC_k6oNei/view?usp=sharing" target="_blank" rel="noreferrer" className="Resume"> <CgFileDocument size={20}/> </a>
        </div>
      </div>
    </section>
  );
}

export default Navbar;
